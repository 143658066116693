@import '../../styles/variables.less';

.ant-table {
  .ant-table-thead > tr > th {
    color: @table-header-color;
    background: @table-header-background;
    font-weight: bold;
  }
  .ant-table-thead .ant-table-cell.ant-table-column-has-sorters {
    padding: 0px 16px;
  }
  .ant-table-tbody > tr > td {
    height: 51px;
    padding: 0px 16px;
  }
  & .ant-table-body {
    overflow-y: auto !important;
  }
  & .ant-table-tbody {
    & .anticon {
      font-size: 12px;
      padding: 0 4px;
    }
    & .ant-btn {
      margin: 0;
      padding: 1px 8px;
      height: auto;
    }
  }
}

.has-scroll-data {
  .ant-table {
    .ant-table-body {
      height: calc(100vh - @table-height-with-data);
    }
  }
}

.has-scroll-data-search {
  .ant-table {
    .ant-table-body {
      height: calc(100vh - @table-height-with-data-search);
    }
  }
}

.no-scroll-data {
  .ant-table {
    .ant-table-body {
      min-height: calc(100vh - @table-height-without-data);
    }
  }
}

.no-scroll-no-data {
  .ant-table {
    .ant-table-body {
      min-height: calc(100vh - @table-height-with-data);
    }
  }
}
